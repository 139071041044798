<template>
  <b-card title="Add Integration">
    <div v-if="loading" class="row mt-4">
      <div class="col-12 d-flex justify-content-center">
        <div class="spinner spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <template v-else>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Partner Key"
            description="Unique key to identify the Partner"
            label-for="partner-key"
            invalid-feedback="Choose one"
            :state="!$v.core_partner_key.$invalid">
            <b-form-select
              id="partner-key"
              v-model="core_partner_key"
              :state="!$v.core_partner_key.$invalid"
              :options="partners"/>
          </b-form-group>
          <b-button variant="success" @click="save" :disabled="creating || $v.$invalid">Save</b-button>
          <b-button variant="danger" @click="$emit('close')">Cancel</b-button>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { list as listPartners } from '@/api/saml/core/partners';
import { create as createIntegration } from '@/api/saml/client/integrations';

export default {
  name: 'ClientAddIntegration',
  beforeMount() {
    this.loading = true;
    listPartners()
      .then(partners => {
        for (let i = 0; i < partners.length; i++) {
          this.partners.push({
            text: partners[i].key,
            value: partners[i].key,
          });
        }
      })
      .catch(err => {
        Vue.prototype.$noty.error(`Failed to fetch partners: ${err}`);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  props: {
    onboardingClientReference: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      creating: false,
      core_partner_key: null,
      partners: [
        { value: null, text: 'Please select a Partner' },
      ],
    };
  },
  validations() {
    return {
      core_partner_key: {
        required,
      },
    };
  },
  methods: {
    save() {
      this.creating = true;
      createIntegration({
        core_partner_key: this.core_partner_key,
        onboarding_client_reference: this.onboardingClientReference,
      })
        .then(() => {
          Vue.prototype.$noty.success('Integration created successfully');
          this.$emit('close', true);
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to create integration: ${err}`);
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
