import axios from 'axios';
import * as R from 'ramda';

export function list() {
  return axios.get('/v1/saml/core/partners')
    .then(({ data: keys }) => {
      const partners = [];
      for (let i = 0; i < keys.length; i++) {
        partners.push({ key: keys[i] });
      }
      return partners;
    });
}

export function create(data) {
  return axios.post('/v1/saml/core/partners', data)
    .then(({ data: { key } }) => key);
}

export function get(key) {
  return axios.get(`/v1/saml/core/partners/${key}`)
    .then(({ data }) => ({
      key: data.key,
      name: data.name,
      configuration: R.pick(
        [
          'force_sso_authentication',
          'enable_sp_initiated_login',
          'disable_password_requirement',
          'enable_signup',
          'signup_url',
          'enable_return_partner',
          'partner_url',
        ],
        data.configuration,
      ),
    }));
}

export function edit(key, name, configuration) {
  return axios.patch(`/v1/saml/core/partners/${key}`, { name, configuration })
    .then(() => {});
}

export default {
  list,
  create,
  get,
  edit,
};
