<template>
  <b-container id="sso-integration-list" class="mt-4" fluid>
    <b-row>
      <b-col cols="6">
        <h3>
          SAML Integrations:
          <router-link :to="{ name: 'EditClient', params: { clientID: client.id } }">{{client.name}}</router-link>
        </h3>
        <router-link :to="{ name: 'ListOnboardingClients' }"
                      class="d-flex justify-content-left align-items-center">
          <b-icon icon="arrow-left" aria-label="Back to integration"/>
          Back to Clients
        </router-link>
      </b-col>
      <b-col cols="6">
        <b-button variant="primary"
                  class="float-right"
                  :disabled="addingIntegration"
                  @click="openAddIntegration">
          <feather type="plus"></feather>
          Add Integration
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="addingIntegration" class="mt-3">
      <b-col>
        <add-integration :onboarding-client-reference="client.reference"
                          @close="closeAddIntegration"/>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <b-table
          :fields="fields" :busy="loading" :items="integrations"
          primary-key="uuid"
          outlined sticky-header="800px" striped small
          empty-text="Nothing to show">

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-button variant="light"
                      @click="toggleState(row.item)"
                      :disabled="editingIntegration">
              <b-icon v-if="row.item.is_active" style="color: green;" font-scale="1" icon="toggle-on" aria-label="Integration enabled"></b-icon>
              <b-icon v-else style="color: red;" font-scale="1" icon="toggle2-off" aria-label="Integration disabled"></b-icon>
            </b-button>
            <b-button variant="light"
                      :to="{ name: 'ClientSamlIntegrationCustomers', params: { client_id: clientId, uuid: row.item.uuid } }">
              <b-icon font-scale="1" icon="eye" aria-label="View integration"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { get as getClient } from '@/api/onboarding/clients';
import { list as listIntegrations, edit as editIntegration } from '@/api/saml/client/integrations';
import AddIntegration from '@/views/Client/SSO/components/AddIntegration.vue';

export default {
  name: 'ListClientSSOIntegration',
  components: {
    AddIntegration,
  },
  data() {
    return {
      loading: false,
      addingIntegration: false,
      editingIntegration: false,
      fields: [
        { key: 'core_partner_key', label: 'Partner' },
        { key: 'actions', label: 'Actions' },
      ],
      client: {
        id: 0,
        name: '',
        reference: '',
      },
      integrations: [],
    };
  },
  computed: {
    clientId() {
      return parseInt(this.$route.params.client_id, 10);
    },
  },
  beforeMount() {
    this.loading = true;

    this.loadClient()
      .then(this.loadList)
      .catch(err => {
        Vue.prototype.$noty.error(`Failed to fetch client: ${err}`);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    openAddIntegration() {
      this.addingIntegration = true;
    },
    closeAddIntegration(refreshList) {
      this.addingIntegration = false;
      if (refreshList) {
        this.loadList();
      }
    },
    toggleState(item) {
      this.editingIntegration = true;
      editIntegration(item.uuid, { is_active: !item.is_active })
        .then(() => {
          item.is_active = !item.is_active;
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to update integration state: ${err}`);
        })
        .finally(() => {
          this.editingIntegration = false;
        });
    },
    loadClient() {
      return getClient(this.clientId)
        .then(client => {
          this.client.id = client?.id;
          this.client.name = client?.display_name;
          this.client.reference = client?.reference;
        });
    },
    loadList() {
      // this method is called before mount and also when a new integration is
      // going to be added. So if the loading is already true, we don't need to
      // set it again and we don't need to set it to false when the promise finishes
      let internalLoading = false;
      if (!this.loading) {
        internalLoading = true;
        this.loading = true;
      }

      this.integrations = [];
      return listIntegrations({ onboarding_client_reference: this.client.reference })
        .then(integrations => {
          this.integrations = integrations;
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to fecth integrations: ${err}`);
        })
        .finally(() => {
          if (internalLoading) {
            this.loading = false;
          }
        });
    },
  },
};
</script>
